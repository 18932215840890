const config = {
  gatsby: {
    pathPrefix: '/',
    siteUrl: 'https://guide.moonalgo.com',
    gaTrackingId: 'G-RMEV3SPL01',
    trailingSlash: false,
  },
  header: {
    logo: '',
    logoLink: 'https://www.moonalgo.com/',
    title:
      '<h1><a href="/">Guide</a></h1>',
    githubUrl: 'https://github.com/h0o0bA/moonalgo-guide',
    helpUrl: '',
    tweetText: '',
    social: `
			<li>
		    <a href="https://discord.gg/S5c7VUAkes" target="_blank" rel="noopener">
		      <div class="discordBtn">
          <img src='data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMS41NTkiIGhlaWdodD0iMTMuMjExIiB2aWV3Qm94PSIwIDAgMTEuNTU5IDEzLjIxMSI+CiAgICA8ZGVmcz4KICAgICAgICA8c3R5bGU+CiAgICAgICAgICAgIC5jbHMtMXtmaWxsOiMyNDI5MmV9CiAgICAgICAgPC9zdHlsZT4KICAgIDwvZGVmcz4KICAgIDxwYXRoIGlkPSJkaXNjb3JkLWJyYW5kcyIgZD0iTTcuNjY5IDYuMjc1QS42NzYuNjc2IDAgMSAxIDcgNS41NDJhLjcuNyAwIDAgMSAuNjY5LjczM3ptLTMuMDg1LS43MzNhLjczNi43MzYgMCAwIDAgMCAxLjQ2Ni43LjcgMCAwIDAgLjY3NC0uNzMzLjcuNyAwIDAgMC0uNjc0LS43MzN6bTYuOTc1LTQuMTgxdjExLjg1Yy0xLjY1OS0xLjQ3MS0xLjEzMi0uOTg0LTMuMDY1LTIuNzgxbC4zNSAxLjIyMmgtNy40OUExLjM1NyAxLjM1NyAwIDAgMSAwIDEwLjI5MXYtOC45M0ExLjM1NyAxLjM1NyAwIDAgMSAxLjM1NCAwaDguODUxYTEuMzU3IDEuMzU3IDAgMCAxIDEuMzU0IDEuMzYxek05LjY3NyA3LjYyM2E4Ljg0NiA4Ljg0NiAwIDAgMC0uOTUxLTMuODUxIDMuMjY2IDMuMjY2IDAgMCAwLTEuODU2LS42OTRsLS4wOTIuMTA2YTQuNCA0LjQgMCAwIDEgMS42NDUuODM5IDUuNjEyIDUuNjEyIDAgMCAwLTQuOTM0LS4xOTJjLS4yNDQuMTEyLS4zOS4xOTItLjM5LjE5MmE0LjQ1NiA0LjQ1NiAwIDAgMSAxLjczNy0uODY1bC0uMDY2LS4wNzlhMy4yNjYgMy4yNjYgMCAwIDAtMS44NTYuNjk0IDguODQ2IDguODQ2IDAgMCAwLS45NTEgMy44NTEgMi40IDIuNCAwIDAgMCAyLjAxNSAxcy4yNDQtLjMuNDQzLS41NDhBMi4wNTQgMi4wNTQgMCAwIDEgMy4yNjMgNy4zYy4xLjA2OC4yNTcuMTU2LjI3MS4xNjVhNC44MTUgNC44MTUgMCAwIDAgNC4xMjIuMjM1IDMuNzggMy43OCAwIDAgMCAuNzYtLjM5IDIuMDg0IDIuMDg0IDAgMCAxLTEuMi43ODZjLjIuMjUxLjQzNi41MzUuNDM2LjUzNWEyLjQxNSAyLjQxNSAwIDAgMCAyLjAyMS0xeiIgY2xhc3M9ImNscy0xIi8+Cjwvc3ZnPgo=' alt='MoonAlgo Discord' />
          </div>
		    </a>
		  </li>`,
    links: [{ text: '', link: '' }],
    search: {
      enabled: true,
      indexName: 'guide',
      algoliaAppId: 'TCFX9H1MMV',
      algoliaSearchKey: '1bc79e6a455b80ab5578500279e988ce',
      algoliaAdminKey: 'a003c9d09630f172a44eaa8c994e1a57',
      // algoliaAppId: process.env.GATSBY_ALGOLIA_APP_ID,
      // algoliaSearchKey: process.env.GATSBY_ALGOLIA_SEARCH_KEY,
      // algoliaAdminKey: process.env.ALGOLIA_ADMIN_KEY,
    },
  },
  sidebar: {
    forcedNavOrder: [
      '/introduction', // add trailing slash if enabled above
      '/codeblock',
    ],
    collapsedNav: [
      '/codeblock', // add trailing slash if enabled above
    ],
    links: [
      { text: 'MoonAlgo', link: 'https://www.moonalgo.com/' },
      { text: 'Affiliate Program', link: 'https://www.moonalgo.com/affiliate' }
    ],
    frontLine: false,
    ignoreIndex: true,
    title:
      "<a href='https://www.moonalgo.com/'>MoonAlgo</a><div class='greenCircle'></div><a href='https://www.moonalgo.com/'>MoonAlgo</a>",
  },
  siteMetadata: {
    title: 'Guide | MoonAlgo',
    description: 'Unlock the power of MoonAlgo\'s comprehensive trading guide. Access expert insights, strategies, and tools for trading stocks, crypto, and forex. Enhance your trading skills and stay ahead of the market with our comprehensive resources. Start optimizing your trading performance today!',
    ogImage: '/assets/images/moonalgo-og.png?reset=1',
    docsLocation: 'https://github.com/h0o0bA/moonalgo-guide/tree/master/content',
    favicon: '/assets/images/icon.png',
  },
  pwa: {
    enabled: true, // disabling this will also remove the existing service worker.
    manifest: {
      name: `MoonAlgo's Guide`,
      short_name: `MoonAlgo's Guide`,
      start_url: `/`,
      background_color: '#6b37bf',
      theme_color: '#6b37bf',
      display: 'standalone',
      crossOrigin: 'use-credentials',
      icon: `src/images/icon.png`,
    },
  },
};

module.exports = config;
